<template>
  <form class="authorization text-center">

<!--    <img class="m-4" src="img/img.png" style="width: 43px; height: 43px; ">-->
    <img class="m-4" src="img/n_logo.png" style="width: 43px; height: 43px; ">

    <h1 class="h3 mb-3 font-weight-normal">Вход в систему</h1>

    <!--    <div class="form-group self-alert">-->
    <!--      {{$store.getters['users/getAuthError']}}-->
    <!--    </div>-->

    <div v-if="$store.getters['users/getAuthError']" class="alert alert-danger" role="alert">
      {{$store.getters['users/getAuthError']}}
    </div>

    <div class="form-group">
      <label for="inputEmail" class="sr-only">Введите логин</label>
      <input type="email" v-model="login" id="inputEmail" class="form-control" placeholder="Логин" required="" autofocus="">
    </div>

    <div class="form-group">
      <label for="inputPassword" class="sr-only">Введите пароль</label>
      <input type="password" v-model="passw" id="inputPassword" class="form-control" placeholder="Пароль" required="">
    </div>

    <!--div class="checkbox mb-3">
      <label>
        <input type="checkbox" value="remember-me"> Remember me
      </label>
    </div-->

    <div class="form-group">
      <button class="btn btn-дп btn-warning btn-block" style="border-radius: 12px;"
              @click.stop.prevent="signin()">Войти</button>
    </div>

  </form>

  <div class="row text-center" style="position:absolute;bottom:0;left: 46.2%">
    <p>Версия 2.25.1</p>
  </div>
</template>

<script>
// import { ref } from 'vue'
// import { useStore } from 'vuex';

export default {
  // setup() {
  //   const store = useStore();
  //   const login = ref('');
  //   const passw = ref('');
  //
  //   function signin() {
  //     if ( login.value.trim() && passw.value.trim() )
  //     {
  //       store.dispatch('users/tryAuthorize', {
  //         login: login.value,
  //         passw: passw.value,
  //       });
  //     }
  //     // this.$emit('checkAuth');
  //   }
  //
  //   return {
  //     login: login,
  //     passw: passw,
  //     signin: signin,
  //   }
  // },
  components: {
  },
  data() {
    return {
      login: '',
      passw: '',
    }
  },
  created() {
    this.$store.dispatch('sidebar/trySetSidebarState', {
      state: true,
    });
    this.$store.dispatch('sidebar/askSidebarState');
  },
  mounted() {
    this.$store.dispatch('sidebar/trySetSidebarState', {
      state: true,
    });
    this.$store.dispatch('sidebar/askSidebarState');
  },
  methods: {
    signin() {
      if ( this.login.trim() && this.passw.trim() )
      {
        this.$store.dispatch('users/tryAuthorize', {
          login: this.login,
          passw: this.passw,
        });
      }
      this.$store.dispatch('sidebar/trySetSidebarState', {
        state: true,
      });
      this.$emit('checkAuth');
    }
  }
}
</script>

<style>
.authorization {
  padding: 20px 0;
  width: 300px;
  margin: 0 auto;
}
</style>

<style scoped>

.self-alert {
  height: 1.5rem;
  color: red;
}
</style>