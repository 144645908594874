const config = {
    //  apiUrl: 'http://176.97.38.151:8005',
    //    apiUrl: 'http://88.214.35.123',    // Prod
   //  Тут поменял www на admin  собстенно порт на admin надо менять на 8005
       apiUrl: 'https://admin.intelligent-decisions.net/api',
    // apiUrl: 'http://192.168.0.96:8005',
    // apiUrl: 'http://88.214.35.58:8005',
    //apiUrl: 'https://m2b.aispeech.online',
    // apiUrl: 'http://va-bo01.vimpelcom.ru/api',
    title: 'VC SA BO App'
};

export default config
