<template>
  <nav id="nav-outer" class="">
    <nav class="navbar navbar-expand-md navbar-dark vc_bg">
      <div class="container-fluid">
        <!--          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar0SupportedContent">-->
        <!--            <span class="navbar-toggler-icon"></span>-->
        <!--          </button>-->
          <ul class="nav navbar-nav me-auto nav-main">
            <li class="nav-item" v-for="(link, index) in headerLinks" 
              :key="index"
              :class="link.klass"
            >
              <div v-if="$store.getters['users/getRole'] === 'user'">
              <a class="nav-link" v-bind:href="''+link.url" v-if="link.url !== '/sa_preferences' && link.url !== '/vc_preferences'"
                data-target="#nav_reports">{{ link.name }}</a>
              </div>
              <div v-else>
                <a class="nav-link" v-bind:href="''+link.url"
                   data-target="#nav_reports">{{ link.name }}</a>
              </div>
            </li>
          </ul>
        <div class="d-flex nav-item">
          <a class="text-secondary text-decoration-none mt-2">{{$store.getters['users/getCompanyName']}}</a>
          <btn class="btn text-secondary"
          ><i class="fa fa-user"></i> {{$store.getters['users/getUserName']}}</btn>
          <btn class="btn text-secondary"
               @click.prevent.stop="logout()"
          ><i class="fa fa-sign-out-alt"></i></btn>
        </div>
      </div>
    </nav>
  </nav>
</template>

<script>
import { useStore } from 'vuex';

export default {
  name: "Header",

  setup()
  {
    const store = useStore();

    function logout()
    {
      store.dispatch('users/tryAuthorize');   
    }

    return {
      logout: logout,
    }
  },

  props: {
    headerLinks: []
  }
}
</script>

<style scoped>

/*.active-cont {*/
/*  margin-left: 399px;*/
/*}*/

</style>