<template>
  <div>
    <Header
        v-model="headerLinks" v-bind:headerLinks="headerLinks"
    ></Header>

    <div class="container mt-2">

      <!--      <button class="btn btn-secondary"><i class="fa fa-plus"></i> Добавить</button>-->
      <button type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-toggle="modal" data-bs-target="#modalUsers" @click="modalNew">
        <i class="fa fa-plus"></i> Добавить
      </button>
      <div class="row">
        <DatatableClientManagement v-if="loaded"
                                   :datatableId="'dt2'"
                                   :tableData="companies"
                                   :columnsData="columnsData2"
                                   @modalOpen="modalOpen"
                                   @deleteData="deleteCompany"/>
        <p v-else>
          Данные для отображения не найдены
        </p>
      </div>
    </div>

    <div class="modal fade" id="modalUsers" aria-labelledby="modalUsers" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Подсказка</h5>
            <button type="button" class="btn close" data-bs-dismiss="modal">
              <!--                <span>×</span>-->
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="mb-3">
                <label for="fi1" class="form-label">Название</label>
                <input type="text" class="form-control" style="border-radius: 12px;" id="fi1" v-model="modalData.name" placeholder="Название">
              </div>
            </div>

            <div class="row">
              <div class="mb-3">
                <label for="fi2" class="form-label">Описание</label>
                <input type="text" class="form-control" style="border-radius: 12px;" id="fi2" v-model="modalData.description" placeholder="Описание">
              </div>
            </div>

            <div class="row">
              <div class="mb-3">
                <label for="fi3" class="form-label">ИНН</label>
                <input type="text" class="form-control" style="border-radius: 12px;" id="fi3" v-model="modalData.inn" placeholder="ИНН" autocomplete="off">
              </div>
            </div>

            <div class="row">
              <div class="mb-3">
                <label for="fi4" class="form-label">Договор</label>
                <!--                <input type="number" class="form-control is-invalid" id="fi4" v-model="modalData.contract" placeholder="Договор"-->
                <!--                       :class="{'is-invalid' : !contractVal, 'is-valid' : contractVal}"-->
                <!--                       @input="contractValidator">-->

                <input type="number" class="form-control is-valid" style="border-radius: 12px;" id="fi4" v-model="modalData.contract" placeholder="Договор"
                       v-if="contractVal"
                       @input="contractValidator">
                <input type="number" class="form-control is-invalid" style="border-radius: 12px;" id="fi4" v-model="modalData.contract" placeholder="Договор"
                       v-else
                       @input="contractValidator">
              </div>
            </div>

            <div class="row">
              <div class="mb-3">
                <label for="fi5" class="form-label">Email Суперадминистратора</label>
                <input type="text" class="form-control" style="border-radius: 12px;" id="fi5" v-model="modalData.email" placeholder="Email">
              </div>
            </div>
            <div class="row">
              <div class="mb-3">
                <label for="fi6" class="form-label">Сгенерировать пароль Суперадминистратора</label>
                <div class="input-group mb-3">
                  <button class="btn btn-secondary" @click="generatePass"><i class="fa fa-sm fa-key"></i></button>
                  <input v-if="!viewPass" class="form-control" id="fi6" v-model="modalData.password" placeholder="Пароль" type="password">
                  <input v-else class="form-control" id="fi6" v-model="modalData.password" placeholder="Пароль" type="text">
                  <span class="input-group-text">
                  <i v-if="!viewPass" class="fa fa-eye" @click="viewPass = !viewPass"
                  ></i>
                   <i v-else class="fa fa-eye-slash" @click="viewPass = !viewPass"
                   ></i>
                    <!--                                         style="cursor: pointer"-->
                 </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="mb-3">
                <!--                <label for="fi6" class="form-label">Список услуг</label>-->
                <!--                <input type="text" class="form-control" id="fi6" v-model="modalData.services" placeholder="Список услуг">-->

                <VueMultiselect
                    v-model="services"
                    :options="servicesOptions"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder="Список услуг"
                    label="name"
                    track-by="name"
                />

              </div>
            </div>

            <div class="row">
              <div class="mb-3">
                <label for="fi7" class="form-label">Статус</label>
                <!--                <input type="number" class="form-control" id="fi7" v-model="modalData.status" placeholder="Статус">-->

                <select v-model="modalData.status" class="form-select" style="border-radius: 12px;" aria-label=".form-select-sm example" id="fi7">
                  <option value="1">Включен</option>
                  <option value="0">Отключен</option>
                </select>

              </div>
            </div>

          </div>
          <div class="modal-footer">
            <template v-if="modalAdd">
              <button v-if="contractVal" type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-dismiss="modal" @click="addNewCompany">
                Добавить
              </button>
              <button v-else type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-dismiss="modal" disabled @click="addNewCompany">
                Добавить
              </button>
            </template>
            <!--            <button v-if="modalAdd" type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="addNewCompany">Добавить</button>-->
            <button v-else type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-dismiss="modal" @click="editCompany">Сохранить</button>
            <button type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-dismiss="modal">Отмена</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
import DatatableClientManagement from "@/components/Datatables/DatatableClientManagement";
import {TCompanies} from "@/utils/coreApi";
import VueMultiselect from 'vue-multiselect';
import generatePassword from "@/utils/passUtils";

export default {
  name: "ClientManagement",
  components: {
    Header,
    DatatableClientManagement,
    VueMultiselect
  },
  data() {
    return {
      headerLinks: [
        {
          name: "Управление клиентами",
          url: "/client_management"
        },
      ],
      groups2: [
        {
          id: 1,
          name: "Клиент 1",
          contract_number: "Клиент 1",
          inn: "user1",
          email: "test1@beeline.ru",
          description: "Описание 1",
          list_subs: "",
        },
        {
          id: 2,
          name: "Клиент 2",
          contract_number: "Клиент 2",
          inn: "user2",
          email: "test2@beeline.ru",
          description: "Описание 2",
          list_subs: "Синтез речи, Распознавание речи",
        },
        {
          id: 3,
          name: "Клиент 3",
          contract_number: "Клиент 3",
          inn: "user3",
          email: "test3@beeline.ru",
          description: "Описание 3",
          list_subs: "Речевая аналитика, Виртуальный оператор",
        },
      ],
      columnsData2: [
        {title: "Наименование", visible: true, key: 'name', sort: true, search: true},
        {title: "Номер договора", visible: true, key: 'contract', sort: true, search: true},
        {title: "ИНН", visible: true, key: 'inn', sort: true, search: true},
        {title: "Email", visible: true, key: 'email', sort: true, search: true},
        {title: "Описание", visible: true, key: 'description', sort: true, search: true},
        {title: "Список услуг", visible: true, key: 'services', sort: true, search: true},
        {title: "Перейти в ЛК клиента", visible: true},
        {title: "Редактировать", visible: true},
        {title: "Заблокировать услугу", visible: true},
        {title: "Удалить", visible: true},
      ],
      loaded: false,
      companies: [],
      modalAdd: false,
      modalData: {},
      services: [],
      servicesOptions: [
        {"name": "Речевая аналитика"},
        {"name": "Распознавание речи"},
        {"name": "Синтез речи"},
        {"name": "Виртуальный оператор"}
      ],
      contractVal: false,
      viewPass: false
    };
  },
  methods: {
    generatePass() {
      this.modalData.password = generatePassword();
      console.log("this.modalData.password", this.modalData.password);
    },
    async getData() {
      this.loaded = false;
      this.companies = [];
      let companiesObj = new TCompanies();
      let companies = await companiesObj.getAll();
      console.log("companies", companies);
      if (companies && companies.length > 0) {
        this.companies = companies;
        if (companies.services) {
          companies.services.forEach(value => {
            this.services.push({"name": value});
          });
        } else {
          this.services = [];
        }
        this.loaded = true;
      }
      // console.log("this.scenarioData", this.scenarioData)
    },
    modalOpen(modalData) {
      this.modalData = modalData;
      console.log("modalData.contract", modalData.contract);
      this.contractVal = !!(modalData.contract && modalData.contract !== '');
      this.services = [];
      if (modalData.services) {
        modalData.services.forEach(value => {
          this.services.push({"name": value});
        });
      } else {
        this.services = [];
      }

      console.log("modalData Open", modalData);

      this.modalAdd = false;
    },
    modalNew() {
      this.services = [];
      this.modalData = {};
      this.modalAdd = true;
    },
    async addNewCompany() {
      let companiesObj = new TCompanies();

      let services = [];
      this.services.forEach(value => {
        services.push(value.name);
      });

      let newData = {
        "name": this.modalData.name,
        "description": this.modalData.description,
        "inn": this.modalData.inn,
        "contract": this.modalData.contract,
        "email": this.modalData.email,
        "services": services,
        "status": this.modalData.status,
        "password": this.modalData.password,
      };
      let resp = await companiesObj.add(newData);
      console.log("resp add", resp);
      await this.getData();
    },
    async editCompany() {
      let companiesObj = new TCompanies();
      let currentData = await companiesObj.get(this.modalData.id);
      if (currentData) {
        let services = [];
        this.services.forEach(value => {
          services.push(value.name);
        });
        let newData = currentData;
        console.log("currentData", currentData);
        newData.name = this.modalData.name;
        newData.description = this.modalData.description;
        newData.inn = this.modalData.inn;
        newData.contract = this.modalData.contract;
        newData.email = this.modalData.email;
        // newData.services = this.modalData.services
        newData.services = services;
        newData.status = this.modalData.status;
        if (this.modalData.password && this.modalData.password !== '' && this.modalData.password !== undefined) {
          newData.password = this.modalData.password;
        } else {
          if (currentData.password !== undefined) {
            newData.password = currentData.password;
          } else {
            newData.password = '';
          }
        }
        console.log("newData", newData);
        let resp = await companiesObj.updateCompany(this.modalData.id, newData);
        console.log("resp add", resp);
        await this.getData();
      }
    },
    async deleteCompany(company) {
      console.log("company", company);
      let companyObj = new TCompanies();
      let resp = await companyObj.delete(company.id);
      console.log("resp delete", resp);
      if (resp.status === 'ok') {
        await this.getData();
      }
    },
    contractValidator() {
      // console.log("value", value)
      this.contractVal = !!this.modalData.contract;
    }
  },
  async mounted() {
    await this.getData();
  }

};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>
