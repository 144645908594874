function searchTable(data, patternData, column) {
    const pattern = patternData.toLocaleLowerCase()
    let newData = []
        data.forEach(element => {
            let m = false
            for (const [key, value] of Object.entries(element)) {
                if (column) {
                    if (key !== 'id' && key === column) {
                        const nval = JSON.stringify(value).toLocaleLowerCase()
                        if (nval.match(pattern)) {
                            m = true
                        }
                    }
                } else {
                    if (key !== 'id') {
                        const nval = JSON.stringify(value).toLocaleLowerCase()
                        if (nval.match(pattern)) {
                            m = true
                        }
                    }
                }
            }
            if (m) {
                newData.push(element)
            }
        })
    return newData;
}

export default searchTable;


